import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { constants } from '@kinsta/base-style';

import { breakpoints } from '@/src/vars';

const { gutter } = constants;

export const Grid = styled.div(() => {
	return css({
		display: 'grid',
		rowGap: gutter * 2,
		columnGap: gutter * 1.5,
		alignItems: 'flex-start',
		gridTemplateAreas: `
			'statA statA statB statB'
			'statC statC statD statD'
			'chartA chartA chartA chartA'
			'chartB chartB chartB chartB'
			'chartC chartC chartC chartC'
			'tableA tableA tableA tableA'
			'tableB tableB tableB tableB'
		`,
		gridTemplateColumns: 'repeat(4, 1fr)',
		[`@media (min-width: ${breakpoints.tablet}px)`]: {
			rowGap: gutter * 3,
			columnGap: gutter * 2,
		},
		[`@media (min-width: ${breakpoints.desktop}px)`]: {
			rowGap: gutter * 5,
			columnGap: gutter * 3,
			gridTemplateAreas: `
				'statA statB statC statD'
				'chartA chartA chartA chartA'
				'chartB chartB chartB chartB'
				'chartC chartC chartC chartC'
				'tableA tableA tableA tableA'
				'tableB tableB tableB tableB'
			`,
		},
	});
});
