import {
	eachDayOfInterval,
	eachMonthOfInterval,
	endOfDay,
	endOfMonth,
	format,
	fromUnixTime,
	startOfDay,
	startOfMonth,
	sub,
} from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';

type node = {
	created?: string | number | null;
	amount?: number;
};

type Interval = {
	name: string;
	dateFormat: string;
	start: Date;
};

const getPercentChange = (oldNumber: number, newNumber: number) => {
	if (oldNumber > 0 && newNumber > 0) {
		return Math.floor(((newNumber - oldNumber) / oldNumber) * 100);
	} else if (newNumber === 0) {
		return oldNumber * -100;
	}

	return newNumber * 100;
};

/**
 * Takes an array of objects (typically nodes from paginated GraphQL queries) containing `created` properties,
 * and transforms them into an array of objects suitable to be passed to a Chart.
 */
const makeChartData = ({
	data,
	interval,
	sumOn,
}: { data?: Maybe<node>[]; interval: Interval; sumOn?: string }) => {
	let intervalDates;
	if (interval.name === 'month') {
		intervalDates = eachMonthOfInterval({
			start: startOfMonth(interval.start),
			end: endOfMonth(new Date()),
		});
	} else {
		intervalDates = eachDayOfInterval({
			start: startOfDay(interval.start),
			end: endOfDay(sub(new Date(), { days: 1 })),
		});
	}

	const log = new Map();
	intervalDates.forEach((date) => {
		const key = format(date, 'yyyy-MM-dd');
		log.set(key, {
			date: key,
			value: 0,
		});
	});

	if (data) {
		data.forEach((node) => {
			const date = fromUnixTime(Number(node?.created) / 1000);
			let entry = format(startOfDay(Number(date)), 'yyyy-MM-dd');
			if (interval.name === 'month') {
				entry = format(startOfMonth(date), 'yyyy-MM-dd');
			}
			const increment = sumOn ? node?.[sumOn as keyof node] : 1;
			const logEntry = log.get(entry);
			if (logEntry) {
				log.set(entry, {
					...logEntry,
					value: logEntry.value + increment,
				});
			}
		});
		let previousKey: string;
		log.forEach((value, key, map) => {
			let percentChange = 0;
			if (previousKey && map.has(previousKey)) {
				percentChange = getPercentChange(
					map.get(previousKey).value,
					value.value,
				);
			}
			map.set(key, {
				...value,
				percentChange,
			});
			previousKey = key;
		});
	}

	return Array.from(log.values());
};

export default makeChartData;
