const centsToUSD = (cents: number) => {
	const dollars = new Intl.NumberFormat('en-US', {
		style: 'decimal',
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	}).format(cents / 100);

	return `${dollars} USD`;
};

export default centsToUSD;
