import { Icon } from '@kinsta/icons';
import { LoadingBlock } from '@kinsta/loading';
import { Tooltip } from '@kinsta/tooltip';
import enUS from 'date-fns/locale/en-US';
import Link from 'next/link';
import { useState } from 'react';

import { Button, DateTime, DisplayError, Table } from '@/src/components';
import { useLocale } from '@/src/hooks';
import {
	useLatestReferralsQuery,
	useReferralsTranslationQuery,
} from '@/src/pages/homepage/_components/ReferralsTable/ReferralsTable.queries.gen';

const ReferralsTable = () => {
	const pageSize = 5;
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState([
		{
			column: 'created',
			order: 'desc',
		},
	]);
	const { data, error } = useLatestReferralsQuery({
		variables: {
			offset: page,
			limit: pageSize,
			sort,
		},
	});

	const { data: dataTranslation } = useReferralsTranslationQuery();
	const locale = useLocale() ?? enUS;

	if (dataTranslation?.user?.__typename !== 'Affiliate') {
		return <LoadingBlock />;
	}

	const { translation } = dataTranslation.user;

	const referrals =
		data?.user?.__typename === 'Affiliate'
			? data.user.referrals.nodes.map((node) => {
					return {
						created: <DateTime timestamp={node.created} locale={locale} />,
						plan: node.stripe.plan,
						view: (
							<Link href={`referrals/${node.id}`} legacyBehavior passHref>
								<Button type='secondary'>{translation.buttons.view}</Button>
							</Link>
						),
					};
			  })
			: undefined;

	if (error) {
		return <DisplayError variant='page' message={error.message} />;
	}

	const handleSortClick = (
		sort: {
			column: string;
			order: string;
		}[],
	) => {
		setPage(0);
		setSort(sort);
	};

	return (
		<Table
			hasTopBorder
			columns={[
				{
					title: translation.tableCols.created,
					dataIndex: 'created',
				},
				{
					title: (
						<>
							<Tooltip content={translation.tableCols.planTooltip}>
								<Icon
									tabIndex={0}
									type='Help'
									style={{ marginRight: '.45rem' }}
								/>
							</Tooltip>
							{translation.tableCols.plan}
						</>
					),
					dataIndex: 'plan',
				},
				{
					title: translation.tableCols.view,
					dataIndex: 'view',
				},
			]}
			sort={sort}
			onSortClick={handleSortClick}
			dataSource={referrals}
			pageSize={pageSize}
			totalCount={
				data?.user?.__typename === 'Affiliate'
					? data?.user.referrals.meta.total
					: 0
			}
			page={page}
			onPageChange={setPage}
			emptyMessage={translation.referrals.noReferrals}
		/>
	);
};

export default ReferralsTable;
