/* eslint-disable */
import * as Types from '../graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type EnumTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EnumTranslationsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', commissionType: { __typename?: 'CommissionTypeTranslation', oneTime: string, recurring: string, custom: string }, serviceType: { __typename?: 'ServiceTypeTranslation', payAsYouGo: string, subscription: string }, commissionPaymentStatus: { __typename?: 'CommissionPaymentStatusTranslation', notApproved: string, approved: string, paymentPending: string, paid: string }, paymentStatus: { __typename?: 'PaymentStatusTranslation', onHold: string, upcoming: string, pending: string, paid: string, failed: string }, affiliateCategory: { __typename?: 'AffiliateCategoryTranslation', publisherBlogger: string, webDevelopmentDesignAgency: string, freelanceDeveloperDesigner: string, themePlugin: string, marketingAgency: string, other: string }, affiliateSubCategory: { __typename?: 'AffiliateSubCategoryTranslation', wordpressContent: string, webHostingContent: string, webDevelopmentContent: string, technologyContent: string, marketingContent: string, businessSiteDevelopmentContent: string, reviewSite: string, other: string, theme: string, plugin: string }, affiliateStatus: { __typename?: 'AffiliateStatusTranslation', active: string, suspended: string, pending: string, rejected: string, banned: string, deleted: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const EnumTranslationsDocument = gql`
    query EnumTranslations {
  user {
    id
    ... on Affiliate {
      translation {
        commissionType {
          oneTime
          recurring
          custom
        }
        serviceType {
          payAsYouGo
          subscription
        }
        commissionPaymentStatus {
          notApproved
          approved
          paymentPending
          paid
        }
        paymentStatus {
          onHold
          upcoming
          pending
          paid
          failed
        }
        affiliateCategory {
          publisherBlogger
          webDevelopmentDesignAgency
          freelanceDeveloperDesigner
          themePlugin
          marketingAgency
          other
        }
        affiliateSubCategory {
          wordpressContent
          webHostingContent
          webDevelopmentContent
          technologyContent
          marketingContent
          businessSiteDevelopmentContent
          reviewSite
          other
          theme
          plugin
        }
        affiliateStatus {
          active
          suspended
          pending
          rejected
          banned
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useEnumTranslationsQuery__
 *
 * To run a query within a React component, call `useEnumTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnumTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnumTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnumTranslationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnumTranslationsQuery, EnumTranslationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<EnumTranslationsQuery, EnumTranslationsQueryVariables>(EnumTranslationsDocument, options);
      }
export function useEnumTranslationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnumTranslationsQuery, EnumTranslationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<EnumTranslationsQuery, EnumTranslationsQueryVariables>(EnumTranslationsDocument, options);
        }
export type EnumTranslationsQueryHookResult = ReturnType<typeof useEnumTranslationsQuery>;
export type EnumTranslationsLazyQueryHookResult = ReturnType<typeof useEnumTranslationsLazyQuery>;
export type EnumTranslationsQueryResult = Apollo.QueryResult<EnumTranslationsQuery, EnumTranslationsQueryVariables>;