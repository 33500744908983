/* eslint-disable */
import * as Types from '../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type IndexQueryVariables = Types.Exact<{
  paidStatus: Types.Scalars['String']['input'];
  upcomingStatus: Types.Scalars['String']['input'];
  pendingStatus: Types.Scalars['String']['input'];
}>;


export type IndexQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', paymentsSum: number, id: string, role: Types.Role, referralsCount: { __typename?: 'PaginatedReferral', meta: { __typename?: 'Meta', total: number } }, upcomingPayment: { __typename?: 'PaginatedPayment', nodes: Array<{ __typename?: 'Payment', amount: number }> }, pendingPayment: { __typename?: 'PaginatedPayment', nodes: Array<{ __typename?: 'Payment', amount: number }> }, allReferredPageviews: { __typename?: 'PaginatedReferredPageview', meta: { __typename?: 'Meta', total: number } }, allReferredVisitors: { __typename?: 'PaginatedReferredVisitor', meta: { __typename?: 'Meta', total: number } } } | { __typename?: 'BareUser', id: string, role: Types.Role } | { __typename?: 'Seller', id: string, role: Types.Role } | null };

export type IndexTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IndexTranslationsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', menu: { __typename?: 'MenuTranslation', dashboard: string }, dashboard: { __typename?: 'DashboardTranslation', activeReferrals: { __typename?: 'ActiveReferralsTranslation', title: string }, referredPageViews: { __typename?: 'ReferredPageViewsTranslation', title: string }, totalPayments: { __typename?: 'TotalPaymentsTranslation', title: string }, nextPayment: { __typename?: 'NextPaymentTranslation', title: string }, paymentPending: { __typename?: 'PaymentPendingTranslation', title: string }, referredVisitorsGraph: { __typename?: 'ReferredVisitorsGraphTranslation', title: string }, subscriptionsReferredGraph: { __typename?: 'SubscriptionsReferredGraphTranslation', title: string }, latestReferralsTable: { __typename?: 'LatestReferralsTableTranslation', title: string }, latestApprovalsTable: { __typename?: 'LatestApprovalsTableTranslation', title: string } } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };

export type AffiliateSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AffiliateSettingsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, settings: { __typename?: 'AffiliateSettings', language: Types.Language } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const IndexDocument = gql`
    query Index($paidStatus: String!, $upcomingStatus: String!, $pendingStatus: String!) {
  user {
    id
    role
    ... on Affiliate {
      referralsCount: referrals(
        filter: [{where: {col: "isActive", op: "=", expr: "1"}}]
        page: {limit: 0}
      ) {
        meta {
          total
        }
      }
      paymentsSum(
        filter: [{where: {col: "Payments.status", op: "=", expr: $paidStatus}}]
      )
      upcomingPayment: payments(
        filter: [{where: {col: "Payments.status", op: "=", expr: $upcomingStatus}}]
      ) {
        nodes {
          amount
        }
      }
      pendingPayment: payments(
        filter: [{where: {col: "Payments.status", op: "=", expr: $pendingStatus}}]
      ) {
        nodes {
          amount
        }
      }
      allReferredPageviews: referredPageviews(page: {limit: 0}) {
        meta {
          total
        }
      }
      allReferredVisitors: referredVisitors(page: {limit: 0}) {
        meta {
          total
        }
      }
    }
  }
}
    `;

/**
 * __useIndexQuery__
 *
 * To run a query within a React component, call `useIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexQuery({
 *   variables: {
 *      paidStatus: // value for 'paidStatus'
 *      upcomingStatus: // value for 'upcomingStatus'
 *      pendingStatus: // value for 'pendingStatus'
 *   },
 * });
 */
export function useIndexQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IndexQuery, IndexQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IndexQuery, IndexQueryVariables>(IndexDocument, options);
      }
export function useIndexLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IndexQuery, IndexQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IndexQuery, IndexQueryVariables>(IndexDocument, options);
        }
export type IndexQueryHookResult = ReturnType<typeof useIndexQuery>;
export type IndexLazyQueryHookResult = ReturnType<typeof useIndexLazyQuery>;
export type IndexQueryResult = Apollo.QueryResult<IndexQuery, IndexQueryVariables>;
export const IndexTranslationsDocument = gql`
    query IndexTranslations {
  user {
    id
    ... on Affiliate {
      translation {
        menu {
          dashboard
        }
        dashboard {
          activeReferrals {
            title
          }
          referredPageViews {
            title
          }
          totalPayments {
            title
          }
          nextPayment {
            title
          }
          paymentPending {
            title
          }
          referredVisitorsGraph {
            title
          }
          subscriptionsReferredGraph {
            title
          }
          latestReferralsTable {
            title
          }
          latestApprovalsTable {
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useIndexTranslationsQuery__
 *
 * To run a query within a React component, call `useIndexTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexTranslationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IndexTranslationsQuery, IndexTranslationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IndexTranslationsQuery, IndexTranslationsQueryVariables>(IndexTranslationsDocument, options);
      }
export function useIndexTranslationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IndexTranslationsQuery, IndexTranslationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IndexTranslationsQuery, IndexTranslationsQueryVariables>(IndexTranslationsDocument, options);
        }
export type IndexTranslationsQueryHookResult = ReturnType<typeof useIndexTranslationsQuery>;
export type IndexTranslationsLazyQueryHookResult = ReturnType<typeof useIndexTranslationsLazyQuery>;
export type IndexTranslationsQueryResult = Apollo.QueryResult<IndexTranslationsQuery, IndexTranslationsQueryVariables>;
export const AffiliateSettingsDocument = gql`
    query AffiliateSettings {
  user {
    id
    ... on Affiliate {
      settings {
        language
      }
    }
  }
}
    `;

/**
 * __useAffiliateSettingsQuery__
 *
 * To run a query within a React component, call `useAffiliateSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAffiliateSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>(AffiliateSettingsDocument, options);
      }
export function useAffiliateSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>(AffiliateSettingsDocument, options);
        }
export type AffiliateSettingsQueryHookResult = ReturnType<typeof useAffiliateSettingsQuery>;
export type AffiliateSettingsLazyQueryHookResult = ReturnType<typeof useAffiliateSettingsLazyQuery>;
export type AffiliateSettingsQueryResult = Apollo.QueryResult<AffiliateSettingsQuery, AffiliateSettingsQueryVariables>;