import { LoadingCell } from '@kinsta/loading';
import { format, sub } from 'date-fns';
import { useState } from 'react';

import { Chart, DisplayError } from '@/src/components';
import {
	useChartTranslationsQuery,
	useReferredPageviewsChartQuery,
} from '@/src/pages/homepage/_components/ReferredPageviewsChart/ReferredPageviewsChart.queries.gen';
import makeChartData from '@/src/utilities/makeChartData';

const ReferredPageviewsChart = () => {
	const [interval] = useState({
		name: 'day',
		dateFormat: 'EEEE',
		start: sub(new Date(), { days: 7 }),
		end: new Date(),
	});
	const { data, loading, error } = useReferredPageviewsChartQuery({
		variables: {
			dateStart: format(interval.start, 'yyyy-MM-dd'),
			dateEnd: format(interval.end, 'yyyy-MM-dd'),
		},
	});

	const { data: translationData } = useChartTranslationsQuery();

	if (error) {
		return <DisplayError variant={'component'} message={error.message} />;
	}

	if (loading) {
		return <LoadingCell />;
	}

	if (data?.user?.__typename !== 'Affiliate') {
		return null;
	}

	if (translationData?.user?.__typename !== 'Affiliate') {
		return null;
	}

	return (
		<Chart
			data={makeChartData({
				data: data?.user?.rangedReferredPageviews?.nodes,
				interval,
			})}
			dayTranslations={translationData.user.translation.dayOfTheWeek}
			dateFormat={interval.dateFormat}
			total={data?.user?.rangedReferredPageviews?.meta.total}
		/>
	);
};

export default ReferredPageviewsChart;
