/* eslint-disable */
import * as Types from '../../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type LatestReferralsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sort: Array<Types.Sort> | Types.Sort;
}>;


export type LatestReferralsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, referrals: { __typename?: 'PaginatedReferral', nodes: Array<{ __typename?: 'Referral', id: string, created: string, isActive: boolean, stripe: { __typename?: 'StripeReferrals', plan: string } }>, meta: { __typename?: 'Meta', total: number } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };

export type ReferralsTranslationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReferralsTranslationQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', tableCols: { __typename?: 'TableColsTranslation', created: string, plan: string, planTooltip: string, view: string }, buttons: { __typename?: 'ButtonsTranslation', view: string }, referrals: { __typename?: 'ReferralsTranslation', noReferrals: string }, status: { __typename?: 'StatusTranslation', canceled: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const LatestReferralsDocument = gql`
    query LatestReferrals($limit: Int!, $offset: Int!, $sort: [Sort!]!) {
  user {
    id
    ... on Affiliate {
      referrals(page: {limit: $limit, offset: $offset}, sort: $sort) {
        nodes {
          id
          created
          isActive
          stripe {
            plan
          }
        }
        meta {
          total
        }
      }
    }
  }
}
    `;

/**
 * __useLatestReferralsQuery__
 *
 * To run a query within a React component, call `useLatestReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestReferralsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLatestReferralsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LatestReferralsQuery, LatestReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LatestReferralsQuery, LatestReferralsQueryVariables>(LatestReferralsDocument, options);
      }
export function useLatestReferralsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestReferralsQuery, LatestReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LatestReferralsQuery, LatestReferralsQueryVariables>(LatestReferralsDocument, options);
        }
export type LatestReferralsQueryHookResult = ReturnType<typeof useLatestReferralsQuery>;
export type LatestReferralsLazyQueryHookResult = ReturnType<typeof useLatestReferralsLazyQuery>;
export type LatestReferralsQueryResult = Apollo.QueryResult<LatestReferralsQuery, LatestReferralsQueryVariables>;
export const ReferralsTranslationDocument = gql`
    query ReferralsTranslation {
  user {
    id
    ... on Affiliate {
      translation {
        tableCols {
          created
          plan
          planTooltip
          view
        }
        buttons {
          view
        }
        referrals {
          noReferrals
        }
        status {
          canceled
        }
      }
    }
  }
}
    `;

/**
 * __useReferralsTranslationQuery__
 *
 * To run a query within a React component, call `useReferralsTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsTranslationQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralsTranslationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferralsTranslationQuery, ReferralsTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReferralsTranslationQuery, ReferralsTranslationQueryVariables>(ReferralsTranslationDocument, options);
      }
export function useReferralsTranslationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferralsTranslationQuery, ReferralsTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReferralsTranslationQuery, ReferralsTranslationQueryVariables>(ReferralsTranslationDocument, options);
        }
export type ReferralsTranslationQueryHookResult = ReturnType<typeof useReferralsTranslationQuery>;
export type ReferralsTranslationLazyQueryHookResult = ReturnType<typeof useReferralsTranslationLazyQuery>;
export type ReferralsTranslationQueryResult = Apollo.QueryResult<ReferralsTranslationQuery, ReferralsTranslationQueryVariables>;