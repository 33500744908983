/* eslint-disable */
import * as Types from '../../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type ReferredVisitorsChartQueryVariables = Types.Exact<{
  dateStart: Types.Scalars['String']['input'];
  dateEnd: Types.Scalars['String']['input'];
}>;


export type ReferredVisitorsChartQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, rangedReferredVisitors: { __typename?: 'PaginatedReferredVisitor', nodes: Array<{ __typename?: 'ReferredVisitor', id: string, created: string }>, meta: { __typename?: 'Meta', total: number } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };

export type ChartTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ChartTranslationsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', dayOfTheWeek: { __typename?: 'DayOfTheWeekTranslation', monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const ReferredVisitorsChartDocument = gql`
    query ReferredVisitorsChart($dateStart: String!, $dateEnd: String!) {
  user {
    id
    ... on Affiliate {
      rangedReferredVisitors: referredVisitors(
        filter: [{where: {col: "created", op: ">=", expr: $dateStart}}, {where: {col: "created", op: "<", expr: $dateEnd}}]
      ) {
        nodes {
          id
          created
        }
        meta {
          total
        }
      }
    }
  }
}
    `;

/**
 * __useReferredVisitorsChartQuery__
 *
 * To run a query within a React component, call `useReferredVisitorsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferredVisitorsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferredVisitorsChartQuery({
 *   variables: {
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useReferredVisitorsChartQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ReferredVisitorsChartQuery, ReferredVisitorsChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ReferredVisitorsChartQuery, ReferredVisitorsChartQueryVariables>(ReferredVisitorsChartDocument, options);
      }
export function useReferredVisitorsChartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferredVisitorsChartQuery, ReferredVisitorsChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ReferredVisitorsChartQuery, ReferredVisitorsChartQueryVariables>(ReferredVisitorsChartDocument, options);
        }
export type ReferredVisitorsChartQueryHookResult = ReturnType<typeof useReferredVisitorsChartQuery>;
export type ReferredVisitorsChartLazyQueryHookResult = ReturnType<typeof useReferredVisitorsChartLazyQuery>;
export type ReferredVisitorsChartQueryResult = Apollo.QueryResult<ReferredVisitorsChartQuery, ReferredVisitorsChartQueryVariables>;
export const ChartTranslationsDocument = gql`
    query ChartTranslations {
  user {
    id
    ... on Affiliate {
      translation {
        dayOfTheWeek {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }
    }
  }
}
    `;

/**
 * __useChartTranslationsQuery__
 *
 * To run a query within a React component, call `useChartTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChartTranslationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChartTranslationsQuery, ChartTranslationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ChartTranslationsQuery, ChartTranslationsQueryVariables>(ChartTranslationsDocument, options);
      }
export function useChartTranslationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChartTranslationsQuery, ChartTranslationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ChartTranslationsQuery, ChartTranslationsQueryVariables>(ChartTranslationsDocument, options);
        }
export type ChartTranslationsQueryHookResult = ReturnType<typeof useChartTranslationsQuery>;
export type ChartTranslationsLazyQueryHookResult = ReturnType<typeof useChartTranslationsLazyQuery>;
export type ChartTranslationsQueryResult = Apollo.QueryResult<ChartTranslationsQuery, ChartTranslationsQueryVariables>;