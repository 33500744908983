import { camelCase } from 'lodash';

import { EnumTranslationsQuery } from '@/src/utilities/enumTranslation/enumTranslation.queries.gen';
import {
	AffiliateCategoryTranslation,
	AffiliateStatusTranslation,
	AffiliateSubCategoryTranslation,
	CommissionPaymentStatusTranslation,
	CommissionTypeTranslation,
	PaymentStatusTranslation,
	ServiceTypeTranslation,
} from '@/src/utilities/graphql-types.gen';

type TranslationTypes = {
	commissionType: CommissionTypeTranslation;
	serviceType: ServiceTypeTranslation;
	commissionPaymentStatus: CommissionPaymentStatusTranslation;
	paymentStatus: PaymentStatusTranslation;
	affiliateCategory: AffiliateCategoryTranslation;
	affiliateSubCategory: AffiliateSubCategoryTranslation;
	affiliateStatus: AffiliateStatusTranslation;
};

type TranslationKeyTypes =
	| CommissionTypeTranslation
	| CommissionPaymentStatusTranslation
	| PaymentStatusTranslation
	| AffiliateCategoryTranslation
	| AffiliateSubCategoryTranslation
	| AffiliateStatusTranslation;

const enumTranslationToLocale = (
	enumGroup: string,
	selection: string,
	data: EnumTranslationsQuery,
) => {
	if (data?.user?.__typename !== 'Affiliate') {
		return null;
	}

	const translationKey = camelCase(selection).split('_').join('');

	return data.user.translation[enumGroup as keyof TranslationTypes][
		translationKey as keyof TranslationKeyTypes
	];
};

export default enumTranslationToLocale;
