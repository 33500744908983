import { Icon } from '@kinsta/icons';
import { LoadingBlock } from '@kinsta/loading';
import { Tooltip } from '@kinsta/tooltip';
import enUS from 'date-fns/locale/en-US';
import Link from 'next/link';
import { useState } from 'react';

import { Button, DateTime, Table } from '@/src/components';
import { useLocale } from '@/src/hooks';
import {
	useLatestCommissionsQuery,
	useLatestCommissionsTranslationQuery,
} from '@/src/pages/homepage/_components/CommissionsTable/CommissionsTable.queries.gen';
import centsToUSD from '@/src/utilities/centsToUSD';
import enumTranslationToLocale from '@/src/utilities/enumTranslation/enumTranslation';
import { useEnumTranslationsQuery } from '@/src/utilities/enumTranslation/enumTranslation.queries.gen';

const CommissionsTable = () => {
	const pageSize = 5;
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState([
		{
			column: 'created',
			order: 'desc',
		},
	]);
	const { data } = useLatestCommissionsQuery({
		variables: {
			offset: page,
			limit: pageSize,
			sort,
		},
	});

	const { data: dataTranslation } = useLatestCommissionsTranslationQuery();
	const { data: enumTranslationsData } = useEnumTranslationsQuery();
	const locale = useLocale() ?? enUS;

	if (dataTranslation?.user?.__typename !== 'Affiliate') {
		return <LoadingBlock />;
	}

	const commissions =
		data?.user?.__typename === 'Affiliate'
			? data.user.commissions.nodes.map((node) => {
					if (dataTranslation.user?.__typename !== 'Affiliate') {
						return {};
					}

					return {
						...node,
						created: <DateTime timestamp={node.created} locale={locale} />,
						amount: centsToUSD(node.amount),
						serviceType: enumTranslationToLocale(
							'serviceType',
							node.serviceType,
							enumTranslationsData ?? {},
						),
						type: enumTranslationToLocale(
							'commissionType',
							node.type,
							enumTranslationsData ?? {},
						),
						view: (
							<Link
								href={`referrals/${node.idReferral}`}
								legacyBehavior
								passHref
							>
								<Button type='secondary'>
									{dataTranslation.user.translation.buttons.view}
								</Button>
							</Link>
						),
					};
			  })
			: undefined;

	const handleSortClick = (
		sort: {
			column: string;
			order: string;
		}[],
	) => {
		setPage(0);
		setSort(sort);
	};

	return (
		<Table
			hasTopBorder
			columns={[
				{
					title: dataTranslation.user.translation.tableCols.approved,
					dataIndex: 'created',
				},
				{
					title: dataTranslation.user.translation.tableCols.amount,
					dataIndex: 'amount',
				},
				{
					title: (
						<>
							<Tooltip
								content={
									dataTranslation.user.translation.tableCols.serviceTypeTooltip
								}
							>
								<Icon
									tabIndex={0}
									type='Help'
									style={{ marginRight: '.45rem' }}
								/>
							</Tooltip>
							{dataTranslation.user.translation.tableCols.serviceType}
						</>
					),
					dataIndex: 'serviceType',
				},
				{
					title: dataTranslation.user.translation.tableCols.type,
					dataIndex: 'type',
				},
				{
					title: dataTranslation.user.translation.tableCols.view,
					dataIndex: 'view',
				},
			]}
			sort={sort}
			dataSource={commissions}
			pageSize={pageSize}
			totalCount={
				data?.user?.__typename === 'Affiliate'
					? data.user.commissions?.meta.total
					: 0
			}
			page={page}
			onPageChange={setPage}
			onSortClick={handleSortClick}
			emptyMessage={
				dataTranslation.user.translation.dashboard.latestApprovalsTable.noData
			}
		/>
	);
};

export default CommissionsTable;
