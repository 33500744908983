import { typography } from '@kinsta/base-style';
import { useRouter } from 'next/router';

import {
	Card,
	CardHeader,
	DisplayError,
	Head,
	PageContent,
	PageLoading,
	PageTitle,
} from '@/src/components';
import {
	CommissionsTable,
	ReferralsTable,
	ReferredPageviewsChart,
	ReferredSubscriptionsChart,
	ReferredVisitorsChart,
} from '@/src/pages/homepage/_components';
import {
	useIndexQuery,
	useIndexTranslationsQuery,
} from '@/src/pages/homepage/index.queries.gen';
import * as Styled from '@/src/pages/homepage/index.style';
import centsToUSD from '@/src/utilities/centsToUSD';
import { PaymentStatus, Role } from '@/src/utilities/graphql-types.gen';

const { Headline, Description } = typography;

const AffiliateDashboard = () => {
	const router = useRouter();
	const { data, loading, error } = useIndexQuery({
		variables: {
			paidStatus: PaymentStatus.PAID,
			upcomingStatus: PaymentStatus.UPCOMING,
			pendingStatus: PaymentStatus.PENDING,
		},
	});

	const { data: dataTranslations } = useIndexTranslationsQuery();

	if (error) {
		return <DisplayError variant='page' message={error.message} />;
	}

	if (loading) {
		return <PageLoading />;
	}

	if (data?.user?.role && data.user.role !== Role.AFFILIATE) {
		const sendTo = {
			[Role.SUPERADMIN]: '/admin',
			[Role.ADMIN_AFFILIATE]: '/admin',
			[Role.SELLER]: '/sales/sellers',
			[Role.ADMIN_SELLER]: '/sales/sellers',
			[Role.MK_REFERRER]: '/login',
		};
		router.push(sendTo[data?.user?.role]);
	}

	if (
		data?.user?.__typename !== 'Affiliate' ||
		dataTranslations?.user?.__typename !== 'Affiliate'
	) {
		return null;
	}

	const upcomingPayment = data?.user?.upcomingPayment?.nodes?.length
		? data?.user?.upcomingPayment?.nodes[0]?.amount
		: 0;
	const estimatedNextPayment =
		upcomingPayment && upcomingPayment >= 50 ? upcomingPayment : 0;

	return (
		<>
			<Head title={dataTranslations.user.translation.menu.dashboard} />

			<PageTitle>{dataTranslations.user.translation.menu.dashboard}</PageTitle>

			<PageContent>
				<Styled.Grid>
					<Card isCenteredContent isPadded style={{ gridArea: 'statA' }}>
						<Headline data-testId='active-referrals-count'>
							{data?.user?.referralsCount?.meta.total}
						</Headline>
						<Description>
							{
								dataTranslations.user.translation.dashboard.activeReferrals
									.title
							}
						</Description>
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statB' }}>
						<Headline data-testId='estimated-next-payment'>
							{centsToUSD(estimatedNextPayment)}
						</Headline>
						<Description>
							{dataTranslations.user.translation.dashboard.nextPayment.title}
						</Description>
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statC' }}>
						<Headline data-testId='total-payments'>
							{centsToUSD(data.user.paymentsSum)}
						</Headline>
						<Description>
							{dataTranslations.user.translation.dashboard.totalPayments.title}
						</Description>
						{Boolean(data.user.pendingPayment.nodes.length) && (
							<Description>
								{centsToUSD(data.user.pendingPayment.nodes[0].amount)}{' '}
								{
									dataTranslations.user.translation.dashboard.paymentPending
										.title
								}
							</Description>
						)}
					</Card>
					<Card isCenteredContent isPadded style={{ gridArea: 'statD' }}>
						<Headline data-testId='referred-pageviews'>
							{data?.user?.allReferredPageviews?.meta.total}
						</Headline>
						<Description>
							{
								dataTranslations.user.translation.dashboard.referredPageViews
									.title
							}
						</Description>
					</Card>
					<Card style={{ gridArea: 'chartA' }}>
						<CardHeader>
							{
								dataTranslations.user.translation.dashboard.referredPageViews
									.title
							}
						</CardHeader>
						<ReferredPageviewsChart />
					</Card>
					<Card style={{ gridArea: 'chartB' }}>
						<CardHeader>
							{
								dataTranslations.user.translation.dashboard
									.referredVisitorsGraph.title
							}
						</CardHeader>
						<ReferredVisitorsChart />
					</Card>
					<Card style={{ gridArea: 'chartC' }}>
						<CardHeader>
							{
								dataTranslations.user.translation.dashboard
									.subscriptionsReferredGraph.title
							}
						</CardHeader>
						<ReferredSubscriptionsChart />
					</Card>
					<Card
						style={{
							gridArea: 'tableA',
							alignSelf: 'flex-start',
						}}
					>
						<CardHeader>
							{
								dataTranslations.user.translation.dashboard.latestReferralsTable
									.title
							}
						</CardHeader>
						<ReferralsTable />
					</Card>
					<Card
						style={{
							gridArea: 'tableB',
							alignSelf: 'flex-start',
						}}
					>
						<CardHeader>
							{
								dataTranslations.user.translation.dashboard.latestApprovalsTable
									.title
							}
						</CardHeader>
						<CommissionsTable />
					</Card>
				</Styled.Grid>
			</PageContent>
		</>
	);
};

export default AffiliateDashboard;
