/* eslint-disable */
import * as Types from '../../../../utilities/graphql-types.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/src/hooks/overriddenApolloClientHooks';
const defaultOptions = {} as const;
export type LatestCommissionsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  sort: Array<Types.Sort> | Types.Sort;
}>;


export type LatestCommissionsQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, commissions: { __typename?: 'PaginatedCommission', nodes: Array<{ __typename?: 'Commission', id: string, created: string, amount: number, serviceType: Types.ServiceType, type: Types.CommissionType, idReferral?: string | null }>, meta: { __typename?: 'Meta', total: number } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };

export type LatestCommissionsTranslationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LatestCommissionsTranslationQuery = { __typename?: 'Query', user?: { __typename?: 'Affiliate', id: string, translation: { __typename?: 'Translation', tableCols: { __typename?: 'TableColsTranslation', approved: string, amount: string, serviceType: string, serviceTypeTooltip: string, type: string, view: string }, buttons: { __typename?: 'ButtonsTranslation', view: string }, dashboard: { __typename?: 'DashboardTranslation', latestApprovalsTable: { __typename?: 'LatestApprovalsTableTranslation', noData: string } }, commissionType: { __typename?: 'CommissionTypeTranslation', oneTime: string, recurring: string, custom: string } } } | { __typename?: 'BareUser', id: string } | { __typename?: 'Seller', id: string } | null };


export const LatestCommissionsDocument = gql`
    query LatestCommissions($limit: Int!, $offset: Int!, $sort: [Sort!]!) {
  user {
    id
    ... on Affiliate {
      commissions(
        filter: [{where: {col: "paymentMethod", op: "!=", expr: "CREDIT"}}]
        page: {limit: $limit, offset: $offset}
        sort: $sort
      ) {
        nodes {
          id
          created
          amount
          serviceType
          type
          idReferral
        }
        meta {
          total
        }
      }
    }
  }
}
    `;

/**
 * __useLatestCommissionsQuery__
 *
 * To run a query within a React component, call `useLatestCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestCommissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLatestCommissionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<LatestCommissionsQuery, LatestCommissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LatestCommissionsQuery, LatestCommissionsQueryVariables>(LatestCommissionsDocument, options);
      }
export function useLatestCommissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestCommissionsQuery, LatestCommissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LatestCommissionsQuery, LatestCommissionsQueryVariables>(LatestCommissionsDocument, options);
        }
export type LatestCommissionsQueryHookResult = ReturnType<typeof useLatestCommissionsQuery>;
export type LatestCommissionsLazyQueryHookResult = ReturnType<typeof useLatestCommissionsLazyQuery>;
export type LatestCommissionsQueryResult = Apollo.QueryResult<LatestCommissionsQuery, LatestCommissionsQueryVariables>;
export const LatestCommissionsTranslationDocument = gql`
    query LatestCommissionsTranslation {
  user {
    id
    ... on Affiliate {
      translation {
        tableCols {
          approved
          amount
          serviceType
          serviceTypeTooltip
          type
          view
        }
        buttons {
          view
        }
        dashboard {
          latestApprovalsTable {
            noData
          }
        }
        commissionType {
          oneTime
          recurring
          custom
        }
      }
    }
  }
}
    `;

/**
 * __useLatestCommissionsTranslationQuery__
 *
 * To run a query within a React component, call `useLatestCommissionsTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestCommissionsTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestCommissionsTranslationQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestCommissionsTranslationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestCommissionsTranslationQuery, LatestCommissionsTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<LatestCommissionsTranslationQuery, LatestCommissionsTranslationQueryVariables>(LatestCommissionsTranslationDocument, options);
      }
export function useLatestCommissionsTranslationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestCommissionsTranslationQuery, LatestCommissionsTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<LatestCommissionsTranslationQuery, LatestCommissionsTranslationQueryVariables>(LatestCommissionsTranslationDocument, options);
        }
export type LatestCommissionsTranslationQueryHookResult = ReturnType<typeof useLatestCommissionsTranslationQuery>;
export type LatestCommissionsTranslationLazyQueryHookResult = ReturnType<typeof useLatestCommissionsTranslationLazyQuery>;
export type LatestCommissionsTranslationQueryResult = Apollo.QueryResult<LatestCommissionsTranslationQuery, LatestCommissionsTranslationQueryVariables>;